import { Box, Divider, Flex, type ModalProps, Text } from '@chakra-ui/react';
import { AnimatePresence, motion, type Variants } from 'framer-motion';
import { useEffect, useState } from 'react';

import { Icon } from '@/components/Icon';
import BoxedSandModal from '@/components/Modals/BoxedSandModal';

import { useCheckoutStore } from '@/store/checkout-store';

import animation from '@/lottie/lottie-check-insurance.json';
import dynamic from 'next/dynamic';

const Lottie = dynamic(
   () => import('@/components/Lottie/Lottie').then((mod) => mod.Lottie),
   { ssr: false },
);

const processingCopies = [
   'Processing insurance data...',
   'Verification can take up to a minute...',
   'Time to grab a coffee...',
];

const animationDuration = 8 * 1000;

const variants: Variants = {
   initial: { opacity: 0, scale: 0 },
   animate: { opacity: 1, scale: 1 },
   exit: { opacity: 0 },
};

interface ProcessingInsuranceModalProps
   extends Omit<ModalProps, 'children' | 'isOpen' | 'onClose'> {}

export const ProcessingInsuranceModal = (
   props: ProcessingInsuranceModalProps,
) => {
   const status = useCheckoutStore((state) => state.status);
   const [processingCopyIndex, setProcessingCopyIndex] = useState(0);

   const isPaymentSuccess = status === 'success';

   // The modal is mounted on page load, we rely on isOpen prop to trigger the animation
   useEffect(() => {
      const interval = setInterval(() => {
         setProcessingCopyIndex((prevIndex) => {
            if (prevIndex === processingCopies.length - 1) {
               clearInterval(interval);

               return prevIndex;
            }

            return prevIndex + 1;
         });
      }, animationDuration);

      return () => {
         clearInterval(interval);
      };
   }, []);

   return (
      <BoxedSandModal isOpen onClose={() => {}} {...props}>
         <Flex direction="column" maxW="25rem" gap={5} alignItems="center">
            <Lottie
               options={{
                  loop: true,
                  autoplay: true,
                  animationData: animation,
               }}
               minH="4.45rem"
               maxW="5rem"
            />

            <Text
               size="paragraphLarge"
               fontWeight={600}
               color="background.primary"
            >
               Hold tight while we check your insurance data
            </Text>

            <Divider borderColor="line.secondary" maxW="5rem" />
            <Box h="5rem" w="15rem" position="relative">
               <AnimatePresence>
                  <Box
                     position="absolute"
                     boxSize="full"
                     inset={0}
                     as={motion.span}
                     variants={variants}
                     animate="animate"
                     transitionDuration="1.5s"
                     exit="exit"
                     key={`${processingCopyIndex}_${isPaymentSuccess}`}
                  >
                     {isPaymentSuccess ? (
                        <Icon
                           name="checkmark-circled"
                           boxSize={8}
                           color="accent.green"
                           display="inline-block"
                        />
                     ) : (
                        <Text>{processingCopies[processingCopyIndex]}</Text>
                     )}
                  </Box>
               </AnimatePresence>
            </Box>
         </Flex>
      </BoxedSandModal>
   );
};
