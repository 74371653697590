import { useQuery, type UseQueryOptions } from '@tanstack/react-query';

import { webApiClient } from '@/api-client/apiClient';

import { queryKeys } from '@/types/query-keys';
import { type SoharInsuranceRequestDto } from '@innerwell/dtos';

export type UseEligibilityVerificationStatusProps = Omit<
   UseQueryOptions<unknown, unknown, SoharInsuranceRequestDto, any>,
   'queryKey' | 'queryFn' | 'initialData'
>;

export const useEligibilityVerificationStatus = (
   props: UseEligibilityVerificationStatusProps = {},
) => {
   return useQuery({
      queryKey: queryKeys.eligibilityVerificationStatus,
      queryFn: async () => {
         const response =
            await webApiClient.insurance.getEligibilityVerificationStatus();
         return response.body;
      },
      refetchInterval: 15 * 1000,
      retry: false,
      ...props,
   });
};
