import { Skeleton, useDisclosure } from '@chakra-ui/react';
import { getErrorMessage } from '@innerwell/utils';
import { useRouter } from 'next/navigation';

import useMyPatient from '@/hooks/react-query/useMyPatient';
import useThemedToast from '@/hooks/useThemedToast';

import { webApiClient } from '@/api-client/apiClient';

import { EligibilityFormIntake } from '../Insurance/EligibilityFormIntake';
import BoxedModal from '../Modals/BoxedModal';
import { usePatientInKetamineProgram } from '@/services/patient/hooks/use-patient-in-ketamine-program';
import { Card } from '../Cards/components/Card';
import { useEligibilityVerificationStatus } from '@/hooks/react-query/useEligibilityVerificationStatus';

const HomeCheckInsuranceEligibilityStep = ({
   onCompleted,
}: {
   onCompleted?: (isEligible: boolean | null) => void;
}) => {
   const { data: patient, isLoading: isLoadingPatient } = useMyPatient();
   const isInKetamineProgram = usePatientInKetamineProgram();

   const eligibilityModal = useDisclosure();

   const { push } = useRouter();
   const { toastError } = useThemedToast();

   const { data: verificationData, isLoading: isVerificationDataLoading } =
      useEligibilityVerificationStatus();

   const handleSwitch = async () => {
      try {
         const response = await webApiClient.programSwitch.getSwitchData();

         const info = response.body;
         if (!info.therapyPlan) {
            toastError('No selected therapy plan found');
            return;
         }

         push(
            `/purchase/plan/${info.switchCategoryId}?${new URLSearchParams({
               therapyPlan: info.therapyPlan,
            }).toString()}`,
         );
      } catch (e) {
         toastError(
            `Failed to get insurance program switch info: ${getErrorMessage(e)}`,
         );
      }
   };

   const isWaitingForStatus = verificationData?.isEligible === null;

   return (
      <>
         <Skeleton
            isLoaded={!isLoadingPatient && !isVerificationDataLoading}
            borderRadius="md"
         >
            <Card minH="none">
               {isWaitingForStatus && !eligibilityModal.isOpen ? (
                  <>
                     <Card.Title>Insurance Feedback Pending</Card.Title>
                     <Card.Text display="flex" flexDir="column" gap={2.5}>
                        <span>
                           We’re still waiting for a response from insurance
                           provider, which can take up to 24 hours.
                        </span>
                        <span>
                           As soon as we have the results, we’ll notify you via
                           email.
                        </span>
                        <span>
                           If you have any questions in the meantime, our Member
                           Support team is here to help.
                        </span>
                     </Card.Text>
                     <Card.ButtonGroup>
                        <Card.ButtonLink href="/support">
                           Contact Member Support
                        </Card.ButtonLink>
                     </Card.ButtonGroup>
                  </>
               ) : (
                  <>
                     <Card.Title>Confirm your insurance coverage</Card.Title>
                     <Card.Text>
                        Please give us the details of your insurance coverage so
                        that we can confirm your eligibility and verify that you
                        are covered.
                     </Card.Text>

                     <Card.ButtonGroup>
                        <Card.Button
                           onClick={eligibilityModal.onOpen}
                           isDisabled={!patient || isWaitingForStatus}
                        >
                           Check Eligibility
                        </Card.Button>

                        {isInKetamineProgram &&
                        patient?.isMedicalIntakeRefused ? (
                           <Card.ButtonLink href="/support" variant="link">
                              Contact Member Support
                           </Card.ButtonLink>
                        ) : (
                           <Card.Button variant="link" onClick={handleSwitch}>
                              Continue Without Insurance
                           </Card.Button>
                        )}
                     </Card.ButtonGroup>
                  </>
               )}
            </Card>
         </Skeleton>
         {eligibilityModal.isOpen && patient ? (
            <BoxedModal
               title="Insurance Eligibility"
               bg="background.secondary"
               {...eligibilityModal}
            >
               <EligibilityFormIntake
                  patient={patient}
                  onCompleted={(isEligible) => {
                     eligibilityModal.onClose();

                     onCompleted?.(isEligible);
                  }}
               />
            </BoxedModal>
         ) : null}
      </>
   );
};

export default HomeCheckInsuranceEligibilityStep;
