import { type ChakraStylesConfig, type SingleValue } from 'chakra-react-select';

export type OptionString = SingleValue<{
   label: string;
   value: string;
}>;

export const chakraSelectStyles: ChakraStylesConfig<OptionString> = {
   option: (provided) => ({
      ...provided,
      fontSize: '1rem',
      color: 'black',

      _selected: {
         background: 'background.primary',
         color: 'white',
      },
   }),
   control: (provided) => ({
      ...provided,
      background: 'white',
   }),
   menuList: (provided) => ({
      ...provided,
      bg: ' white',
      mb: 3,
      maxHeight: '300px',
   }),
};
