import { Accordion, Button, Flex, Skeleton } from '@chakra-ui/react';
import {
   IntakeProgramPhases,
   type PatientParamsResponseDto,
   TherapyPlan,
   type WelkinProgramPhases,
} from '@innerwell/dtos';
import { isIntakeProgramAfterOrEqual } from '@innerwell/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import useMyClinician from '@/hooks/react-query/useMyClinician';
import useMyPatient from '@/hooks/react-query/useMyPatient';
import usePatientParams from '@/hooks/react-query/usePatientParams';
import useUpcomingAppointments from '@/hooks/react-query/useUpcomingAppointments';

import HomeStepUpcomingProgress from '@/components/HomeSteps/HomeStepUpcomingProgress';

import { usePatientProgram } from '@/contexts/patient-program-context';
import { formatClinician } from '@/utils/formatting';
import optionsLocalStorage from '@/utils/optionsLocalStorage';

import { PatientLocked } from '../Cards/PatientLocked/PatientLocked';
import { useUserAccess } from '../Cards/PatientLocked/useUserAccess';
import HomeCheckInsuranceEligibilityStep from '../HomeSteps/HomeCheckInsuranceEligibilityStep';
import { HomeConfirmContactConsent } from '../HomeSteps/HomeConfirmContactConsent';
import MedicalConsultStep from '../HomeSteps/Intake/Onboarding/MedicalConsultStep';
import { StepAccordionWrapper } from '../HomeSteps/StepAccordionWrapper';
import { UpcomingAppointments } from '../Swipers/UpcomingAppointments';
import { PatientDischargedCard } from '../Cards/PatientDischargedCard';
import usePatientLastCarePlanChoice from '@/hooks/react-query/usePatientLastCarePlanChoice';
import { InsuranceStatusCard } from '../Cards/InsuranceStatusCard';
import { usePatientHasFinishedConsent } from '@/services/patient/hooks/use-patient-has-finished-consent';
import { getStaggerVariants } from '@/utils/animation-utils';
import { motion } from 'framer-motion';
import { Card } from '../Cards/components/Card';
import { DividerWithText } from '../Dividers/DividerWithText';
import { SkeletonWithLoader } from '../Loaders/SkeletonWithLoader';
import { HomeOnboardingStep } from '../HomeSteps/Intake/Onboarding/HomeOnboardingStep';
import { SimpleMoodTracking } from '../MoodTracking/SimpleMoodTracking';
import { useTodayMoodTracking } from '@/hooks/react-query/useTodayMoodTracking';
import { WeeklyTrackAccordion } from '../MoodTracking/WeeklyTrackAccordion';

enum IntakeSteps {
   Onboarding,
   CheckEligibility,
   MedicalConsult,
   SetTreatmentPlan,
}

const getStep = (
   phase: WelkinProgramPhases | null,
   params: PatientParamsResponseDto | null,
) => {
   if (
      phase === IntakeProgramPhases.InformedConsentFormFinished ||
      phase === IntakeProgramPhases.DailyMoodTrackingFinished ||
      phase === IntakeProgramPhases.SetProgramGoalsFinished
   ) {
      return params?.shouldCheckInsuranceEligibility
         ? IntakeSteps.CheckEligibility
         : IntakeSteps.MedicalConsult;
   }

   // We're not sure until we have params whether to push into MedicalConsult or not
   if (
      isIntakeProgramAfterOrEqual(
         phase,
         IntakeProgramPhases.SetProgramGoalsFinished,
      )
   ) {
      return IntakeSteps.MedicalConsult;
   }

   return IntakeSteps.Onboarding;
};

const staggerVariants = getStaggerVariants({
   staggerChildren: 0.15,
});

export const IntakeLayout = () => {
   const {
      programPhase: { phase },
   } = usePatientProgram();
   const { chosenCarePlan } = usePatientLastCarePlanChoice();
   const patientHasFinishedConsent = usePatientHasFinishedConsent();

   const { push } = useRouter();

   const { params, isLoading: isLoadingPatientParams } = usePatientParams();

   const [isCompletedStepsHidden, setIsCompletedStepsHidden] = useState(() => {
      return optionsLocalStorage.getIsIntakeCompletedStepsHidden();
   });

   const { data: patient } = useMyPatient();

   const scheduleMeetTitle =
      chosenCarePlan === TherapyPlan.EMDR
         ? 'Schedule your EMDR Intake'
         : patient?.insurance?.planType === 'psychotherapist'
           ? 'Meet your Therapist'
           : 'Schedule your Medical Consult';

   const [currentStep, setCurrentStep] = useState<IntakeSteps>(() => {
      return getStep(phase, params);
   });

   // If phase changes because of invalidated query
   useEffect(() => {
      setCurrentStep(getStep(phase, params));
   }, [phase, params]);

   useEffect(() => {
      if (phase === IntakeProgramPhases.MedicalConsultReject) {
         push('/rejected/medical-consult');
      }
      if (phase === IntakeProgramPhases.MedicalIntakeRejected) {
         push('/rejected/intake');
      }
   }, [push, phase]);

   const { isLocked, statuses } = useUserAccess();

   const handleOnboardingCompleted = () => {
      if (patientHasFinishedConsent === true) {
         setCurrentStep(IntakeSteps.MedicalConsult);
         return;
      }

      if (params?.shouldCheckInsuranceEligibility) {
         setCurrentStep(IntakeSteps.CheckEligibility);
         return;
      }

      setCurrentStep(IntakeSteps.MedicalConsult);
   };

   const stepsProgress = useMemo(() => {
      const progress: { [key in string]: 'upcoming' | 'completed' } = {
         onboarding: 'upcoming',
         medicalConsult: 'upcoming',
      };

      if (
         [IntakeSteps.MedicalConsult, IntakeSteps.CheckEligibility].includes(
            currentStep,
         )
      ) {
         progress.onboarding = 'completed';

         return progress;
      }

      return progress;
   }, [currentStep]);

   const handleHideCompletedStepsBtnClick = () => {
      setIsCompletedStepsHidden((prev) => {
         optionsLocalStorage.setIsIntakeCompletedStepsHidden(!prev);
         return !prev;
      });
   };

   const { clinician } = useMyClinician();
   const formattedClinician = clinician ? formatClinician(clinician) : null;

   const { appointments: upcomingAppointments, status: appointmentsStatus } =
      useUpcomingAppointments();

   const { data: moodTrackingData } = useTodayMoodTracking();

   return (
      <>
         <Flex flexDir="column" gap={{ base: 4, lg: 8 }}>
            <HomeConfirmContactConsent />

            {Boolean(patient?.isPatientDischarged) && <PatientDischargedCard />}

            <InsuranceStatusCard />
         </Flex>

         {isLocked ? (
            <PatientLocked statuses={statuses} />
         ) : patient?.currentProgram?.currentPhase.name ===
           IntakeProgramPhases.MedicalConsultOnly ? (
            <Flex
               as={motion.div}
               variants={staggerVariants.container}
               initial="hidden"
               animate="show"
               flexDir="column"
               gap={{ base: 4, lg: 6 }}
            >
               <motion.div variants={staggerVariants.child}>
                  <Flex flexDir="column" gap={3}>
                     <SimpleMoodTracking
                        didTrackToday={moodTrackingData?.didTrackToday}
                        flex={1}
                     />

                     {moodTrackingData?.sendWeeklyPause ? (
                        <WeeklyTrackAccordion />
                     ) : null}
                  </Flex>
               </motion.div>

               <motion.div variants={staggerVariants.child}>
                  <Card>
                     <Card.Image
                        imagePosition="center"
                        alt={formattedClinician?.fullName ?? 'Avatar'}
                        src={
                           formattedClinician?.imageUrl ??
                           '/images/ben-medrano-peach.jpg'
                        }
                        borderRadius={
                           formattedClinician?.imageUrl ? '12px' : '50%'
                        }
                     />

                     <Card.Title>
                        Let’s take the next step, together.
                     </Card.Title>
                     {patient?.insurance?.isValid ? (
                        <Card.Text>
                           Your clinician has determined that you are a great
                           fit for a ketamine program at Innerwell.
                        </Card.Text>
                     ) : (
                        <Card.Text>
                           Your clinician has determined that you are a great
                           fit for a ketamine program at Innerwell.
                           <br />
                           <br />
                           Use code <strong>UPGRADE175</strong> to save $175 on
                           your purchase.
                        </Card.Text>
                     )}

                     <Card.ButtonGroup>
                        <Card.ButtonLink
                           href={
                              patient?.insurance?.isValid
                                 ? `/purchase/insurance/36`
                                 : '/plans/choose-a-plan'
                           }
                        >
                           Purchase now
                        </Card.ButtonLink>
                     </Card.ButtonGroup>
                  </Card>
               </motion.div>

               <motion.div variants={staggerVariants.child}>
                  <DividerWithText mb={4} text="Your upcoming appointments" />
                  <SkeletonWithLoader
                     loadingText="Loading upcoming appointments..."
                     minH={
                        appointmentsStatus === 'pending' ||
                        (upcomingAppointments && upcomingAppointments.length)
                           ? '56px'
                           : 0
                     }
                     borderRadius="12px"
                     isLoaded={appointmentsStatus !== 'pending'}
                     mb={5}
                  >
                     <UpcomingAppointments
                        appointments={upcomingAppointments}
                     />
                  </SkeletonWithLoader>
               </motion.div>
            </Flex>
         ) : (
            <Flex flexDir="column">
               {stepsProgress.onboarding === 'completed' && (
                  <Button
                     variant="link"
                     color="white"
                     onClick={handleHideCompletedStepsBtnClick}
                     mb={isCompletedStepsHidden ? 1 : 5}
                     fontSize="body"
                     ml="auto"
                     mt={2}
                  >
                     {isCompletedStepsHidden
                        ? 'Show completed'
                        : 'Hide completed'}
                  </Button>
               )}

               <motion.div
                  variants={staggerVariants.container}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
               >
                  <Accordion index={[currentStep]}>
                     <StepAccordionWrapper
                        initialComponent={
                           !isCompletedStepsHidden ? (
                              <HomeStepUpcomingProgress
                                 status="completed"
                                 title="Onboarding"
                                 variant="light"
                                 fill="background.primary"
                                 opacity={0.5}
                              />
                           ) : undefined
                        }
                        expandedComponent={
                           <HomeOnboardingStep
                              onCompleted={handleOnboardingCompleted}
                           />
                        }
                     />

                     <StepAccordionWrapper
                        initialComponent={
                           params?.shouldCheckInsuranceEligibility &&
                           !isCompletedStepsHidden ? (
                              <HomeStepUpcomingProgress
                                 status={
                                    currentStep === IntakeSteps.MedicalConsult
                                       ? 'completed'
                                       : 'upcoming'
                                 }
                                 title="Check your insurance eligibility"
                                 variant="light"
                                 fill="background.primary"
                              />
                           ) : undefined
                        }
                        expandedComponent={
                           params?.shouldCheckInsuranceEligibility ? (
                              <HomeCheckInsuranceEligibilityStep
                                 onCompleted={(isEligible) => {
                                    if (isEligible) {
                                       setCurrentStep(
                                          IntakeSteps.MedicalConsult,
                                       );
                                    }
                                 }}
                              />
                           ) : undefined
                        }
                     />

                     <StepAccordionWrapper
                        initialComponent={
                           <HomeStepUpcomingProgress
                              title={scheduleMeetTitle}
                              variant="light"
                              fill="background.primary"
                           />
                        }
                        expandedComponent={
                           <Skeleton
                              isLoaded={!isLoadingPatientParams}
                              borderRadius="lg"
                           >
                              <MedicalConsultStep />
                           </Skeleton>
                        }
                     />
                  </Accordion>

                  <motion.div variants={staggerVariants.child}>
                     <HomeStepUpcomingProgress
                        title="Start your treatment plan"
                        variant="light"
                        fill="background.primary"
                     />
                  </motion.div>
               </motion.div>
            </Flex>
         )}
      </>
   );
};
